import "./Contact.scss";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import ContactForm from "../../components/ContactForm/ContactForm.js";
import Footer from "../../components/Footer/Footer";
import logo from "../../assets/images/ocyan-logo.png";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className="contact">
      <Link to="/">
        <img src={logo} alt="OCYAN" className="logo"></img>
      </Link>
      <div className="contact__main">
        <SectionHeader title="Contact Us"></SectionHeader>
        <div className="contact__container">
          <div className="contact__left">
            <div>
              <p className="contact__text">Let's chat! </p>
              <p className="homepage__contact-text">
                Please complete the contact form and a member of our team will
                be in touch shortly.
              </p>
            </div>
            <div className="contact__email">
              <p className="contact__email-title">
                Alternatively, e-mail us directly at:
              </p>
              <div className="contact__email-container">
                <a
                  className="contact__mailto"
                  href="mailto:hello@ocyan.co.uk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="contact__mailto-text"> hello@ocyan.co.uk</p>
                </a>
              </div>
            </div>
          </div>
          <div className="contact__right">
            <ContactForm />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Contact;
