import { Link } from "react-router-dom";
import "./NavDesktop.scss";

export default function NavDesktop() {
  return (
    <nav className="navDesktop">
      <div className="navDesktop__container">
        <Link to="/">
          <p className="navDesktop__text">HOME</p>
        </Link>
        <Link className="navDesktop__contact" to="/contact">
          <button className="navDesktop__contact-button">GET IN TOUCH</button>
        </Link>
      </div>
    </nav>
  );
}
