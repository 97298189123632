import "./Hero.scss";
import { Link } from "react-router-dom";

import down from "../../assets/icons/down-chevron.png";

const Hero = (props) => {
  // let pageHeight = window.innerHeight;
  let buttonClick = props.clickHandler;

  return (
    <div className="hero">
      <div className="hero__content">
        <h2 className="hero__text hero__text--desktop">
          Complex<br></br>Technologies<br></br>Simplified
        </h2>
        <h2 className="hero__text hero__text--mobile">
          Complex<br></br>Technologies<br></br>Simplified
        </h2>

        <Link className="hero__contact" to="/contact">
          <button className="hero__contact-button">GET IN TOUCH</button>
        </Link>
      </div>

      <div className="hero__down">
        <img
          src={down}
          onClick={buttonClick}
          className="hero__down-button"
          alt="Button"
        ></img>
      </div>
    </div>
  );
};

export default Hero;
