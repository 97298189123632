import "./SectionHeader.scss";

const SectionHeader = (props) => {
  return (
    <div className="sectionHeader">
      <div className="sectionHeader__title">
        <h2 className="sectionHeader__text">{props.title}</h2>
        <div className="sectionHeader__horizontalFrame"></div>
      </div>
    </div>
  );
};

export default SectionHeader;
