import "./Hamburger.scss";

const Hamburger = (props) => {
  const isOpen = props.isOpen;
  return (
    <>
      <div className="hamburger">
        <div className="burger burger1" />
        <div className="burger burger2" />
        <div className="burger burger3" />
      </div>

      <style jsx>{`
        .burger1 {
          transform: ${isOpen ? "rotate(45deg)" : "rotate(0)"};
          background-color: ${isOpen ? "black" : "white"};
        }
        .burger2 {
          transform: ${isOpen ? "translateX(100%)" : "translateX(0)"};
          opacity: ${isOpen ? 0 : 1};
          background-color: ${isOpen ? "black" : "white"};
        }
        .burger3 {
          transform: ${isOpen ? "rotate(-45deg)" : "rotate(0)"};
          background-color: ${isOpen ? "black" : "white"};
        }
      `}</style>
    </>
  );
};

export default Hamburger;
