import "./Footer.scss";
import logo from "../../assets/images/ocyan-logo.png";

const Footer = () => {
  return (
    <div className="footer">
      <img src={logo} alt="OCYAN" className="footer__logo"></img>
      <div>
        <h5 className="footer__heading">Email</h5>
        <a
          href="mailto:hello@ocyan.co.uk"
          className="footer__link"
          target="_blank"
          rel="noreferrer"
        >
          hello@ocyan.co.uk
        </a>
      </div>
      <div>
        <h5 className="footer__heading">Address</h5>
        <p className="footer__text">
          Ocyan Ltd,<br></br>7 Bell Yard, <br></br>
          London, <br></br>
          WC2A 2JR
        </p>
      </div>
      <p className="footer__copyright">
        © 2024 Ocyan Ltd. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
