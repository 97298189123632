import { useRef } from "react";
import emailjs from "@emailjs/browser";

import "./ContactForm.scss";

const ContactForm = () => {
  const form = useRef();
  const inputs = useRef();

  const clearForm = () => {
    document.querySelectorAll("input, textarea").forEach((element) => {
      element.value = "";
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "default_service",
        "template_cotgij4",
        form.current,
        "Geabxz0GSnBAo0uzL"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent!");
          clearForm();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <form className="form" ref={form}>
      <input
        className="form__input"
        placeholder="Name *"
        type="text"
        name="user_name"
        ref={inputs}
      />

      <input
        className="form__input"
        placeholder="E-mail *"
        type="email"
        name="user_email"
        ref={inputs}
      />

      <input
        className="form__input"
        placeholder="Telephone"
        type="text"
        name="user_phone"
        ref={inputs}
      />

      <textarea
        className="form__message"
        placeholder="Message *"
        name="message"
        ref={inputs}
      />
      <button className="form__button" onClick={sendEmail}>
        SEND
      </button>
    </form>
  );
};

export default ContactForm;
