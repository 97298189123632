import "./Home.scss";
import { Link } from "react-router-dom";

import Hero from "../components/Hero/Hero";
import SectionHeader from "../components/SectionHeader/SectionHeader";
import ContactForm from "../components/ContactForm/ContactForm";
import Footer from "../components/Footer/Footer";

import logo from "../assets/images/ocyan-logo.png";
import rocket from "../assets/icons/rocket.png";
import tools from "../assets/icons/tools.png";
import completed from "../assets/icons/completed.png";
import tick from "../assets/icons/tick.png";
import logos from "../assets/images/home-logos.png";
import smallBubble from "../assets/graphics/small-bubble.png";
import quoteBubble from "../assets/graphics/quote-bubble.png";

const Home = () => {
  const handleClickScroll = (ref) => {
    const element = document.getElementById("slide1");
    element?.scrollIntoView({ behavior: "smooth", inline: "start" });
  };

  return (
    <div className="scroll">
      <div className="homepage">
        <Link to="/">
          <img src={logo} className="logo" alt="Ocyan logo"></img>
        </Link>
        <div className="slide">
          <Hero clickHandler={handleClickScroll}></Hero>
        </div>

        <div className="slide1" id="slide1">
          <section>
            <div className="homepage__intro">
              <h1 className="homepage__intro-heading">
                Ocyan: Pioneering Tomorrow's Technology
              </h1>
              <p>
                At Ocyan, we are dedicated to helping our clients bring their
                most ambitious technology visions to life. Our team of
                experienced software and hardware experts work closely with
                businesses to design, develop, and implement cutting-edge
                solutions that drive innovation and drive success.
              </p>
              <p>
                Whether you're looking to improve your existing products or
                create entirely new ones, Ocyan has the expertise and experience
                to help you achieve your goals.
              </p>
            </div>

            <div className="homepage__resultsContainer">
              <div className="homepage__result homepage__result1">
                <img
                  src={tools}
                  className="homepage__result-icon"
                  alt="tools icon"
                ></img>
                <div className="homepage__result-divider homepage__result1-divider"></div>
                <p className="homepage__result-text">
                  We provide software and hardware consultancy services to
                  businesses.
                </p>
              </div>

              <div className="homepage__result homepage__result2">
                <img
                  src={rocket}
                  className="homepage__result-icon"
                  alt="rocket icon"
                ></img>
                <div className="homepage__result-divider homepage__result2-divider"></div>
                <p className="homepage__result-text">
                  Our team of experts work to design and develop innovative
                  solutions.
                </p>
              </div>

              <div className="homepage__result homepage__result3">
                <img
                  src={completed}
                  className="homepage__result-icon"
                  alt="completed icon"
                ></img>
                <div className="homepage__result-divider homepage__result3-divider"></div>
                <p className="homepage__result-text">
                  We help businesses bring their visions to life, driving
                  innovation and success.
                </p>
              </div>
            </div>
          </section>
        </div>

        <div className="slide2">
          <section>
            <SectionHeader title="Why Ocyan?"></SectionHeader>
            <div className="homepage__pointsContainer">
              <div className="homepage__point">
                <img
                  src={tick}
                  className="homepage__point-icon"
                  alt="check"
                ></img>
                <p>Experienced and highly skilled team of tech experts.</p>
              </div>

              <div className="homepage__point">
                <img
                  src={tick}
                  className="homepage__point-icon"
                  alt="check"
                ></img>
                <p>Flexible, adaptable and customisable solutions.</p>
              </div>

              <div className="homepage__point">
                <img
                  src={tick}
                  className="homepage__point-icon"
                  alt="check"
                ></img>
                <p>
                  Focus on innovation, pushing the boundaries of what is
                  possible.
                </p>
              </div>

              <div className="homepage__point">
                <img
                  src={tick}
                  className="homepage__point-icon"
                  alt="check"
                ></img>
                <p>Friendly and collaborative approach.</p>
              </div>

              <img
                className="homepage__quote-smallBubble"
                src={smallBubble}
                alt="bubble"
              ></img>
            </div>

            <div className="homepage__testimonial">
              <img
                src={logos}
                className="homepage__testimonial-logos"
                alt="Testimonial logos"
              ></img>
              <div className="homepage__quote">
                <div className="homepage__quote-container">
                  <img
                    className="homepage__quote-largeBubble"
                    src={quoteBubble}
                    alt="quote"
                  ></img>
                  <p className="homepage__quote-text">
                    “Working with Ocyan has been an absolute pleasure. Their
                    team of experts truly understand how to bring our technology
                    visions to life and have been an invaluable partner in the
                    development of our products.”
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="slide3">
          <section>
            <SectionHeader title="Have a project to transform?"></SectionHeader>
            <div className="homepage__contact">
              <div className="homepage__contact-left">
                <div>
                  <p className="homepage__contact-text">Let's chat! </p>
                  <p className="homepage__contact-text">
                    Please complete the contact form and a member of our team
                    will be in touch shortly.
                  </p>
                </div>

                <div className="homepage__email">
                  <p className="homepage__email-title">
                    Alternatively, e-mail us directly at:
                  </p>
                  <div className="homepage__email-container">
                    <a
                      className="homepage__mailto"
                      href="mailto:hello@ocyan.co.uk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="homepage__mailto-text">hello@ocyan.co.uk</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="homepage__contact-right">
                <ContactForm />
              </div>
            </div>
          </section>
        </div>

        <div className="slide4">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
