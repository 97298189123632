import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import DotLoader from "react-spinners/DotLoader";

import NavMobile from "./components/NavMobile/NavMobile";
import NavDesktop from "./components/NavDesktop/NavDesktop";

import Home from "./pages/Home";
import Contact from "./pages/Contact/Contact";

import "./App.scss";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="app">
      {loading ? (
        <DotLoader color="#342593" size={100} loading={loading} />
      ) : (
        <main>
          <NavMobile id="navElement"></NavMobile>
          <NavDesktop />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      )}
    </div>
  );
}

export default App;
